<template>
  <div class="wrap">
    <div class="no-data-yet" v-if="!tcItems.length">
      <img class="no-data-bg" src="../assets/img/no-data-bg-pic.png" alt="">
      <!-- <img class="back-btn" @click="backBtn" src="../assets/img/back-btn.png" alt=""> -->
      <div class="back-btn" @click="backBtn">
        <van-icon name="arrow-left" />
        {{ $t('main.return') }}
      </div>
      <div class="search">
        <img class="search-user-icon" src="../assets/img/search-user.png" alt="" />
        <input type="search" class="search-st" :maxlength="10" v-model="tcName" @keyup.enter="showTeacher" />

        <!-- <img
          class="search-btn"
          src="../assets/img/search-btn.png"
          alt=""
          
        /> -->
        <div class="search-btn" @click="showTeacher">{{ $t('main.search') }}</div>
      </div>
      <div class="recommend" v-if="tsList.length">
        <div class="text-seacher">{{ $t('main.queryrecord') }}</div>
        <img class="delete-icon" @click="deleteList" src="../assets/img/delete-icon-zm.png" alt="">
        <div class="select-btn">
          <div class="small-btn" v-for="item in tsList" :key="item.id" @click="btnSeatch(item)">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="show-tc-detail" v-if="tcItems.length">
      <div class="wrap-tc">
        <!-- <img class="back-btn" @click="backBtn" src="../assets/img/back-btn.png" alt=""> -->
        <div class="back-btn" @click="backBtn">
          <van-icon name="arrow-left" />
          {{ $t('main.return') }}
        </div>
        <div class="search">
          <img class="search-user-icon" src="../assets/img/search-user.png" alt="" />
          <input type="text" class="search-st" :maxlength="10" v-model="tcName" @keyup.enter="showTeacher($event)" />
          <!-- <img
          class="search-btn"
          src="../assets/img/search-btn.png"
          alt=""
          @click="showTeacher($event)"
        /> -->
          <div class="search-btn" @click="showTeacher">{{ $t('main.search') }}</div>
        </div>
        <div class="detail-box" v-for="(item) in tcItems" :key="item.id">
          <div class="detail-left">
            <div class="title">
              <h3>{{ item.name }}</h3>
              <img v-if="item.type == 1" src="../assets/img/tc-author.png" alt="" />
              <img v-if="item.type == 2 && item.is_collector_inst == 0" src="../assets/img/tc-Collector.png" alt="" />
              <img style="width:0.65rem;height:0.18rem" v-if="item.type == 2 && item.is_collector_inst == 1"
                src="../assets/img/scjg.png" alt="" />
            </div>
            <!-- <div class="count">
            <span class="h3">作品数量：</span>
            <span class="container">{{ item.goods_count }}</span>
          </div> -->
            <div class="brief-introduction">
              <span class="h3">{{ item.is_collector_inst == 1 ? $t('main.intoCoInst') : $t('main.personI') }}：</span>
              <span class="container">{{ item.content }}</span>
            </div>
          </div>
          <div class="detail-image">
            <img v-lazy="(item.pic + '?width=216')" alt="" />
          </div>
          <div class="honor" v-if="item.honor">
            <img v-if="item.is_collector_inst == 0" src="../assets/img/tc-honor.png" alt="" />
            <img v-else src="../assets/img/scjgry.png" alt="" />
          </div>
          <div class="honor-detail">
            <div v-html="item.honor"></div>
          </div>
          <div class="box-footer">
            <!-- <img src="../assets/img/tc-button.png" alt="" @click="tcDetail(item.id)" /> -->
            <div class="viewDetail" @click="tcDetail(item.id)">{{ $t('main.viewDetail') }}</div>
          </div>
        </div>
      </div>

    </div>
    <!-- <div class="pop-pus" v-show="showPrpPus">
      <img class="bg-img" src="../assets/img/no-data-prompt.png"  alt="" />
      <div class="title-prompt">{{ prpText }}</div>
      <img class="sure-img" src="../assets/img/no-data-sure.png"  @click="concatShow" alt="">
    </div> -->
    <div class="pop-pus" v-show="showPrpPus">
      <div class="bg-img">
        <div class="title-wrap">
          <div class="title-prompt">{{ prpText }}</div>
        </div>
        <div class="sure-logo">
          <img class="sure-img" src="../assets/img/no-data-sure.png" @click="concatShow" alt="">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Icon } from 'vant';

export default {
  data() {
    return {
      tcName: "",
      showPrpPus: false,
      tsList: [],
      tcItems: [{
        content: "",
        created_at: "",
        goods_count: "",
        honor: "",
        id: 0,
        name: "",
        pic: "",
        title: "",
        type: ""
      }],
      id: "",
      honorContent: [],
      longValue: "",
      prpText: ""
    };
  },
  mounted() {
    
    this.tcName = this.$route.query.tcName
    if(this.$route.query.__params){
      this.tcName = JSON.parse(this.$base64.decode(this.$route.query.__params)).tcName
    }

    // this.axios.post(`/artist/getRecommendList`).then((res) => {
    //   this.tsList = res.data.data.list ? res.data.data.list : [];

    // });

    this.tsList = JSON.parse(localStorage.getItem("tcName")) ? JSON.parse(localStorage.getItem("tcName")) : []


    this.axios
      .post(`/artist/getArtistList`, { keyword: this.tcName })
      .then((res) => {
        let list = res.data.data.items ? res.data.data.items : [];
        for (var i = 0; i < list.length; i++) {
          list[i].honor = list[i].honor ? list[i].honor.replace(/(\r\n|\n|\r)/gm, ' <br /> ') : ''
          Object.keys(list[i]).forEach((item) => {
            if (this.$i18n.locale == 'en') {
              if (item.indexOf('_intl') != -1) {
                let noitem = item.split('_intl')[0]
                if (list[i][item]) {
                  list[i][noitem] = list[i][item]
                }
              }
            }
            list[i][item] = list[i][item] ? list[i][item] : ""
          })
        }
        this.tcItems = list
      });

  },
  methods: {
    showTeacher(e) {
      // if(this.longValue === this.tcName){
      //   this.longValue = this.tcName
      //     this.prpText = `当前就是${this.tcName}老师`
      //     this.showPrpPus = true
      //   return
      // }
      e.target.blur()
      if (this.tcName.length === 1) {
        this.longValue = this.tcName
        this.prpText = this.$t('main.petfullmname')
        this.showPrpPus = true;
        return;
      } else if (!this.tcName.length) {
        this.longValue = this.tcName
        this.prpText = this.$t('main.penameofyouwant')
        this.showPrpPus = true
        return
      } else if (this.longValue === this.tcName) {
        this.longValue = this.tcName
        return
      }

      if (this.tsList.indexOf(this.tcName) === -1) {
        let lodTcName = JSON.parse(localStorage.getItem("tcName")) ? JSON.parse(localStorage.getItem("tcName")) : []
        lodTcName.unshift(this.tcName)
        if (lodTcName.length > 10) {
          lodTcName.pop()
        }
        localStorage.setItem("tcName", JSON.stringify(lodTcName))
        this.tsList = lodTcName
      }

      history.replaceState(null, null, `${this.$route.path}?tcName=${this.tcName}`)
      this.axios
        .post(`/artist/getArtistList`, { keyword: this.tcName })
        .then((res) => {
          let list = res.data.data.items ? res.data.data.items : [];
          for (var i = 0; i < list.length; i++) {
            list[i].honor = list[i].honor ? list[i].honor.replace(/(\r\n|\n|\r)/gm, ' <br /> ') : ''
            Object.keys(list[i]).forEach((item) => {
              if (this.$i18n.locale == 'en') {
                if (item.indexOf('_intl') != -1) {
                  let noitem = item.split('_intl')[0]
                  if (list[i][item]) {
                    list[i][noitem] = list[i][item]
                  }
                }
              }
              list[i][item] = list[i][item] ? list[i][item] : ""
            })
          }
          this.tcItems = list
        });
      this.longValue = this.tcName
    },
    concatShow() {
      this.showPrpPus = false;
    },
    btnSeatch(tcName) {
      this.tcName = tcName;
      history.replaceState(null, null, `${this.$route.path}?tcName=${this.tcName}`)
      this.axios
        .post(`/artist/getArtistList`, { keyword: this.tcName })
        .then((res) => {
          let list = res.data.data.items ? res.data.data.items : [];
          for (var i = 0; i < list.length; i++) {
            list[i].honor = list[i].honor ? list[i].honor.replace(/(\r\n|\n|\r)/gm, ' <br /> ') : ''
            Object.keys(list[i]).forEach((item) => {
              if (this.$i18n.locale == 'en') {
                if (item.indexOf('_intl') != -1) {
                  let noitem = item.split('_intl')[0]
                  if (list[i][item]) {
                    list[i][noitem] = list[i][item]
                  }
                }
              }
              list[i][item] = list[i][item] ? list[i][item] : ""
            })
          }
          this.tcItems = list
        });
    },
    tcDetail(id) {
      history.replaceState(null, null, `${this.$route.path}?tcName=${this.tcName}`)
      this.$router.push( `/tcdetail/${id}`)
    },
    backBtn() {
      this.$router.push('/')
    },
    deleteList() {
      // weui.alert('确定要清除查询记录吗？', {
      // 			buttons: [{
      // 				label: this.$t('main.cancel'),
      // 				type: 'primary',
      // 				onClick: () => {

      // 				}
      // 			}, {
      // 				label: this.$t('main.determine'),
      // 				onClick: () => {
      //           localStorage.removeItem("tcName")
      //           this.tsList=[]
      // 				}
      // 			}]
      // 		})
      localStorage.removeItem("tcName")
      this.tsList = []
    }
  },
};
</script>

<style scoped>
.no-data-yet {
  min-height: 100vh;
  background-image: url("../assets/img/home_bg.png");
  background-size: cover;
  background-position: center;
  padding: 0.1rem;
  position: relative;
  box-sizing: border-box;
}

.no-data-bg {
  position: absolute;
  width: 2rem;
  top: 42%;
  left: 23%;
}

.back-btn {
  position: absolute;
  top: 0.1rem;
  width: 0.7rem;
  height: 0.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #b0502f;
  background: #f6c972;
  border-radius: 0.08rem;
  font-size: 0.15rem;
  box-shadow: inset 0 0px 0 3px #f6c972, inset 0 0px 0 4px #b0502f;
}

.search {
  width: 79%;
  background-color: white;
  margin-left: 0.73rem;
  border-radius: 0.05rem;
  position: relative;
  height: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.search .search-user-icon {
  width: 0.2rem;
  margin-top: 0.01rem;
  position: absolute;
  left: 0.15rem;
  top: 0.15rem;
}

.search .search-btn {
  width: 0.6rem;
  height: 0.33rem;
  position: absolute;
  top: 0.08rem;
  left: 2.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 0.15rem;
  color: #fff;
  background: #a8210e;
  border-radius: 0.05rem;
}

.recommend {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 0.1rem;
  padding: 0.15rem;
  margin-top: 0.1rem;
  position: relative;
}

.delete-icon {
  width: 0.15rem;
  height: 0.15rem;
  position: absolute;
  top: 0.2rem;
  right: 0.2rem;
  filter: brightness(2);
}

.search .search-st {
  margin-left: 0.5rem;
  border: none;
  width: 1.5rem;
  font-size: 0.16rem;
  background-color: white;
}

.text-seacher {
  color: #e8dedc;
  margin-bottom: 0.1rem;
  font-size: 0.14rem;
}

.select-btn {
  display: flex;
  flex-wrap: wrap;
}

.select-btn .small-btn {
  padding: 0.03rem 0.14rem;
  border: 0.01rem solid white;
  border-radius: 0.15rem;
  color: #fff;
  margin-right: 0.08rem;
  margin-bottom: 0.1rem;
  background-color: rgba(255, 255, 255, 0.5);
  font-size: 0.13rem;
}

.show-tc-detail {
  min-height: 100vh;
  background-image: url("../assets/img/home_bg.png");
  background-size: cover;
  background-position: center;
  box-sizing: border-box;
}

.wrap-tc {
  padding: 0.1rem;
}

.detail-box {
  background-image: url(../assets/img/tc-box-background.png);
  background-size: 100% 100%;
  border-radius: 0.1rem;
  padding: 0.15rem;
  display: flex;
  flex-wrap: wrap;
  margin-top: 0.2rem;
}

.detail-left {
  width: 60%;
}

.detail-left .title {
  font-size: 0.14rem;
  display: flex;
  flex-wrap: wrap;
}

.detail-left .title img {
  width: 0.51rem;
  height: 0.19rem;
  margin-left: 0.1rem;
  margin-top: 0.04rem;
}

.detail-image {
  width: 40%;
  height: 1.7rem;
  overflow: hidden;
}

.detail-image img {
  width: 92%;
  margin-left: 10%;
}

.count {
  margin-top: 0.1rem;
  display: flex;
  flex-wrap: wrap;
}

.count .h3 {
  font-weight: 600;
  font-size: 0.15rem;
  color: #333;
}

.count .container {
  font-size: 0.15rem;
  color: #525252;
}

.brief-introduction .h3 {
  font-weight: 600;
  font-size: 0.15rem;
  color: #333;
}

.brief-introduction .container {
  font-size: 0.15rem;
  color: #525252;
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
}

.honor img {
  height: 0.18rem;
  width: 100%;
  object-fit: contain;
  margin: 0.03rem 0;
}

.honor-detail div {
  font-size: 0.15rem;
  color: #525252;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.box-footer {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 0.1rem 0 0.05rem;
}

.box-footer img {
  height: 0.4rem;
}

.pop-pus {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba(0, 0, 0, .5);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.pop-pus .bg-img {
  width: 70%;
  height: 1.5rem;
  background: url(../assets/img/no-data-prompt.png) no-repeat center;
  background-size: 100% 100%;
  position: relative;
}

.pop-pus .title-wrap {
  position: absolute;
  top: 0;
  width: 100%;
  height: 0.6rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-top: 0.15rem;

}

.pop-pus .title-prompt {
  color: white;
  font-size: 0.15rem;
  width: 1.8rem;
  text-align: center;
}

.pop-pus .sure-logo {
  position: absolute;
  bottom: 0;
  margin-bottom: 0.25rem;
  width: 100%;
  padding: 0 0.3rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
}

.pop-pus .sure-img {
  width: 70%;
}

.viewDetail {
  width: 1.77rem;
  height: 0.4rem;
  background: url(../assets/img/apply_button.png) no-repeat;
  background-size: 100% 100%;
  font-size: 0.18rem;
  color: #333;
  display: flex;
  justify-content: center;
  align-items: center;

}</style>
